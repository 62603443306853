<template>
    <div>
      <div class="system_footer">
        <Header id='4'> </Header>
        <router-view class="limit_width"></router-view>
      </div>
      <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  components: { Header,Footer },
  data() {
    return {
    }
  }
}
</script>

<style scoped>
.limit_width{
    width: 1500px;
    height: auto;
    margin: 0 auto;
    padding-top: 150px;
    padding-bottom: 40px;
}
.system_footer{
   min-height: calc(100vh - 60px);
}
@media screen and (max-width : 1664px) {
  .limit_width{
    width: 1000px;
    height: auto;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 26px;
  }
  .system_footer{
    min-height: calc(100vh - 40px);
  }
}
</style>